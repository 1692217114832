import { Howl, Howler } from "howler";
const sound1 = require("./sound/audio-1.wav");
const sound2 = require("./sound/audio-2.mp3");
const sound3 = require("./sound/audio-3.mp3");
const sound4 = require("./sound/audio-4.mp3");
const sound5 = require("./sound/audio-5.mp3");
const sound6 = require("./sound/audio-6.mp3");
const sound7 = require("./sound/audio-7.mp3");
const sound8 = require("./sound/audio-8.mp3");

let loaded = 0;

let doneLoad = () => {
  if (loaded < 7) {
    loaded++;
  } else {
    document.getElementById("loading-screen").style.display = "none";
  }
};

let tracks = [
  new Howl({
    src: [sound1],
    onload: doneLoad,
  }),
  new Howl({
    src: [sound2],
    onload: doneLoad,
  }),
  new Howl({
    src: [sound3],
    onload: doneLoad,
  }),
  new Howl({
    src: [sound4],
    onload: doneLoad,
  }),
  new Howl({
    src: [sound5],
    onload: doneLoad,
  }),
  new Howl({
    src: [sound6],
    onload: doneLoad,
  }),
  new Howl({
    src: [sound7],
    onload: doneLoad,
  }),
  new Howl({
    src: [sound8],
    onload: doneLoad,
  }),
];
document.addEventListener("DOMContentLoaded", () => {
  let images = document.getElementsByClassName("img");

  for (let i = 0; i < images.length; i++) {
    images[i].addEventListener("mouseenter", () => {
      console.log(tracks[i]);
      tracks[i].play();
    });
    images[i].addEventListener("mouseleave", () => {
      console.log(tracks[i]);
      tracks[i].stop();
    });
    images[i].addEventListener("touchend", () => {
      tracks.forEach((track) => {
        track.stop();
      });
      tracks[i].play();
    });
  }
});
